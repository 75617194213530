<template>
  <div>
    <vx-card ref="filterCard" class="user-list-filters mb-8 mt-6">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Chatbot</label>
          <v-select
            v-model="chatOption"
            :options="chatOptions"
            :clearable="false"
            class="mb-4 md:mb-0"
          />
        </div>
        <div id="dateFilter" class="vx-col md:w-1/4 sm:w-2/3 w-full">
          <label class="text-sm opacity-75">Data</label>
          <vs-select
            icon="icon-calendar"
            icon-pack="feather"
            class="no-icon-border cursor-pointer w-full"
            placeholder="Filtre por data"
            size="small"
            v-model="filterParam"
          >
            <vs-select-item
              v-for="(item, index) in timeOptions"
              :key="index"
              :value="item"
              :text="item"
              class="w-full"
            />
            <div @click="popupDatePicker = true">
              <vs-select-item
                :value="timeOptionCustomValue"
                :text="timeOptionCustomText"
                class="w-full"
              />
            </div>
          </vs-select>
        </div>
        <!-- date-picker -->
        <vs-popup
          class="holamundo"
          :title="timeOptionCustomText"
          :active.sync="popupDatePicker"
        >
          <!-- popup body -->
          <date-picker
            ref="customDatePicker"
            :key="customDatePickerKey"
            v-model="datePickerValue"
            :default-value="datePickerValueDefault"
            :disabled-date="disabledDate"
            format="DD-MM-YYYY"
            range
            inline
            confirm
            @change="handleConfirmDatePicker"
          />
        </vs-popup>
        <div class="right-0 absolute hidden md:block">
          <vs-tooltip
            :text="
              hasDownloadBlocked
                ? 'Função indisponível para esta conta'
                : $t('DownloadXLSX')
            "
          >
            <vs-button
              icon-pack="feather"
              icon="icon-file"
              size="medium"
              class="ml-4 mr-6 mt-6"
              @click="downloadCSV()"
              :disabled="hasDownloadBlocked"
              >{{ $t("DownloadXLSX") }}</vs-button
            >
          </vs-tooltip>
        </div>
      </div>
      <div class="vx-row">
        <div id="chatbotFilter" class="vx-col mt-3 w-full">
          <p class="text-sm opacity-75">{{ $t("InfoDash") }}</p>
        </div>
      </div>
    </vx-card>

    <div class="mt-8">
      <h3 class="m-2">Atendimentos encerrados</h3>
      <div class="vx-row">
        <ChartEndedConversations
          :dateRange="getDateRange"
          :endedConversations="endedConversations.closedConversations"
          :chartData="performanceData.endedConversations"
          :chartKey="endedConversationsKey"
          :key="`endendconv-${endedConversationsKey}`"
        />
        <CardConversationEnded
          v-if="endedConversations"
          :finishReasons="endedConversations"
          :key="`endendconv2-${endedConversationsKey}`"
        />
        <CardLostBusinessReason
          :lostBusinessReasons="endedConversations"
          :key="`endendconv3-${endedConversationsKey}`"
        />
      </div>
    </div>

    <div class="mt-8">
      <TableTeamData
        :tableTitle="'Performance por atendente'"
        :teamData="teamData"
        :key="`userper-${userPerformanceKey}`"
      />
    </div>

    <div class="mt-8">
      <TableEntityData
        :tableTitle="'Performance por entidade chave'"
        :entityData="keyEntities"
        :key="`entitydata-${entityDataKey}`"
      />
    </div>

    <div class="mt-8">
      <TableTypeData
        tableTitle="Performance por tipo"
        :typeData="typeData"
        :key="`typeDataKey-${typeDataKey}`"
      />
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

import CardConversationEnded from "./components/CardConversationEnded.vue";
import CardLostBusinessReason from "./components/CardLostBusinessReason.vue";
import ChartEndedConversations from "./components/ChartEndedConversations.vue";

//date picker
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import xlsx from "xlsx/dist/xlsx.full.min";
import TableEntityData from "./components/TableEntityData.vue";
import TableTeamData from "./components/TableTeamData.vue";
import TableTypeData from "./components/TableTypeData.vue";

import dateFormatter from "@/helpers/dateFormatter";

export default {
  mounted() {
    this.getChats();
    this.getEndedConversations(this.getDateByPeriod, "all");
  },
  components: {
    vSelect,
    CardLostBusinessReason,
    CardConversationEnded,
    ChartEndedConversations,
    DatePicker,
    TableEntityData,
    TableTeamData,
    TableTypeData,
  },
  watch: {
    filterParam: function () {
      if (this.filterParam === this.timeOptionCustomText) {
        // window.alert('custom data selected')
        this.popupDatePicker = true;
        return;
      }
      this.applyFilters(this.filterParam);
    },
    chatOption: function () {
      this.applyFilters(this.filterParam);
    },
  },
  data() {
    return {
      customDatePickerKey: 0,
      popupDatePicker: false,
      datePickerValueDefault: [
        new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000), // 90 days ago
        new Date(), // today's date
      ],
      datePickerValue: null,
      chatOptions: [{ label: this.$t("AllChatbots"), value: "all" }],
      chatOption: [],
      teamMembers: [],
      teamMembersKey: 0,
      keyEntities: null,
      entityData: [],
      entityDataKey: 0,
      typeData: [],
      typeDataKey: 0,
      performanceKey: 0,
      performanceData: {},
      newConversations: Number,
      endedConversations: Array,
      surveyData: {},
      userPerformanceData: [],
      teamData: [],
      filterDateNames: this.$t("FilterDates"),
      userPerformanceKey: 0,
      endedConversationsKey: 0,
      filterParam: "Este mês",
      timeOptions: [
        "Este mês",
        "Mês passado",
        "Esta semana",
        "Semana passada",
        "Este ano",
        "Ano passado",
      ],
      timeOptionCustomValue: "Escolher Data",
      timeOptionCustomText: "Escolher Data",
      dataFilter: "thisMonth",
    };
  },
  computed: {
    getDateByPeriod() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      let rangeDate;
      switch (this.filterParam) {
        case "Este mês":
          rangeDate = {
            start: new Date(today.getFullYear(), today.getMonth(), 1),
            end: today,
          };
          break;
        case "Esta semana": {
          const firstDayOfWeek = today.getDate() - today.getDay();
          rangeDate = {
            start: new Date(
              today.getFullYear(),
              today.getMonth(),
              firstDayOfWeek
            ),
            end: today,
          };
          break;
        }
        case "Este ano":
          rangeDate = {
            start: new Date(today.getFullYear(), 0, 1),
            end: today,
          };
          break;
        case "Semana passada": {
          const lastWeekStart = new Date(today);
          lastWeekStart.setDate(today.getDate() - today.getDay() - 7);
          const lastWeekEnd = new Date(lastWeekStart);
          lastWeekEnd.setDate(lastWeekStart.getDate() + 7);
          rangeDate = {
            start: lastWeekStart,
            end: lastWeekEnd,
          };
          break;
        }
        case "Mês passado":
          rangeDate = {
            start: new Date(today.getFullYear(), today.getMonth() - 1, 1),
            end: new Date(today.getFullYear(), today.getMonth(), 1, 0),
          };
          break;
        case "Ano passado":
          rangeDate = {
            start: new Date(today.getFullYear() - 1, 0, 1),
            end: new Date(today.getFullYear(), 0, 1),
          };
          break;
        case "Escolher Data":
          rangeDate = {
            start: new Date(this.datePickerValue[0]),
            end: new Date(this.datePickerValue[1]),
          };
          break;
        default:
          rangeDate = {
            start: new Date(today.getFullYear(), today.getMonth(), 1),
            end: today,
          };
      }

      return rangeDate;
    },
    getDateRange() {
      let dateMatch;
      if (this.dataFilter === "thisMonth") {
        const thisMonth = new Date();
        thisMonth.setHours(0, 0, 0, 0);

        const firstDay = new Date(
          thisMonth.getFullYear(),
          thisMonth.getMonth(),
          1
        );

        const lastDay = new Date(thisMonth);

        lastDay.setDate(lastDay.getDate() - 1);

        dateMatch = { start: firstDay, end: lastDay };
      }

      if (this.dataFilter === "lastMonth") {
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        lastMonth.setHours(0, 0, 0, 0);

        const firstDay = new Date(
          lastMonth.getFullYear(),
          lastMonth.getMonth(),
          1
        );
        const lastDay = new Date(
          lastMonth.getFullYear(),
          lastMonth.getMonth() + 1,
          0
        );

        dateMatch = { start: firstDay, end: lastDay };
      }

      if (this.dataFilter === "thisWeek") {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const first = today.getDate() - today.getDay();
        const last = first + 6;

        const startThisWeek = new Date(today.setDate(first));
        startThisWeek.setHours(0, 0, 0, 0);
        const endThisWeek = new Date(today.setDate(last));
        endThisWeek.setHours(0, 0, 0, 0);

        endThisWeek.setDate(endThisWeek.getDate() - 1);

        dateMatch = { start: startThisWeek, end: endThisWeek };
      }

      if (this.dataFilter === "lastWeek") {
        const dayOfLastWeek = new Date();
        dayOfLastWeek.setHours(0, 0, 0, 0);
        dayOfLastWeek.setDate(dayOfLastWeek.getDate() - 7);

        const first = dayOfLastWeek.getDate() - dayOfLastWeek.getDay();
        const last = first + 6;

        const startLastWeek = new Date(dayOfLastWeek.setDate(first));
        startLastWeek.setHours(0, 0, 0, 0);
        const endLastWeek = new Date(dayOfLastWeek.setDate(last));
        endLastWeek.setHours(0, 0, 0, 0);

        dateMatch = { start: startLastWeek, end: endLastWeek };
      }

      if (this.dataFilter === "thisYear") {
        const currentYear = new Date().getFullYear();

        const firstDayOfYear = new Date(currentYear, 0, 1);
        firstDayOfYear.setHours(0, 0, 0, 0);

        const lastDayOfYear = new Date(currentYear, 11, 31);
        lastDayOfYear.setHours(0, 0, 0, 0);

        dateMatch = { start: firstDayOfYear, end: lastDayOfYear };
      }

      if (this.dataFilter === "lastYear") {
        const lastYear = new Date();
        lastYear.setFullYear(lastYear.getFullYear() - 1);

        const firstDayOfYear = new Date(lastYear.getFullYear(), 0, 1);
        firstDayOfYear.setHours(0, 0, 0, 0);

        const lastDayOfYear = new Date(lastYear.getFullYear(), 11, 31);
        lastDayOfYear.setHours(0, 0, 0, 0);
        dateMatch = { start: firstDayOfYear, end: lastDayOfYear };
      }
      return dateMatch;
    },
    disabledDate() {
      const today = new Date();
      // return (date) => date < minDate || date > today;
      today.setHours(0, 0, 0, 0);

      return (date) => date >= today;
    },
    hasDownloadBlocked() {
      return this.$store.state.acc.current_acc.blockDownloadExcel;
    },
  },
  methods: {
    handleConfirmDatePicker() {
      this.popupDatePicker = false;
      this.customDatePickerKey += 1;
      this.$nextTick(() => {
        const customDatePickerText = this.$refs.customDatePicker.text;
        this.timeOptionCustomText = !customDatePickerText
          ? this.timeOptionCustomText
          : customDatePickerText;
      });
      this.applyFilters(this.timeOptionCustomText);
    },
    async applyFilters(param) {
      if (param !== this.timeOptionCustomText) {
        this.datePickerValue = null;
        this.timeOptionCustomText = this.timeOptionCustomValue;
      }

      await this.getEndedConversations(
        this.getDateByPeriod,
        this.chatOption.value
      );
    },
    async getChats() {
      await this.$http
        .get("/p/chat/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then((res) => {
          if (this.chatOptions.length == 1) {
            this.activeChats = false;
            this.chatOption = this.chatOptions[0];
            for (var i = 0; i < res.data.data.length; i++) {
              this.chatOptions.push({
                label: res.data.data[i].name,
                value: res.data.data[i]._id,
              });
              if (res.data.data[i].status == 1) this.activeChats = true;
              if (res.data.data[i].status == 3) this.pendingChats = true;
            }
          }
        })
        .catch(() => {});
    },

    async getEndedConversations(dateFilter, chatbot) {
      const response = await this.$http.get(
        "/p/chat/inbox/new-dashboard/performance-2",
        {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            filter: dateFilter,
            chatbot,
          },
        }
      );

      if (response.status === 200) {
        this.endedConversations = response.data.data;

        this.keyEntities = response.data.data.keyEntities;
        this.teamData = response.data.data.performanceData;
        this.typeData = response.data.data.originData;

        this.endedConversationsKey += 1;
        this.userPerformanceKey += 1;
        this.entityDataKey += 1;
        this.typeDataKey += 1;

        this.surveyData = {
          csat: response.data.data.csat,
          nps: response.data.data.nps,
        };
      }
    },

    async downloadCSV() {
      window.analytics.track(
        "Download Performance",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      this.$vs.loading();
      await this.$http
        .get("/p/chat/inbox/conversationsCsv", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            filter: this.getDateByPeriod,
            // status: 4,
            chatbot:
              this.chatOption.value !== "all" ? this.chatOption.value : null,
          },
        })
        .then((res) => {
          const {
            chats,
            contacts,
            conversations,
            operators,
            operatorsData,
            surveys,
          } = res.data.data;

          const XLSX = xlsx;
          const workbook = XLSX.utils.book_new();

          const arrayXlsx = [];

          const headers = {
            createdAt: "Data Entrada",
            closingDate: "Data Encerrado",
            conversationId: "ID da conversa",
            chatName: "Chatbot",
            name: "Nome",
            phone: "Telefone",
            lgpd: "LGPD",
            email: "Email",
            origin: "Tipo",
            channel: "Canal",
            status: "Status Atual",
            intention: "Intenção Atual",
            entity: "Entidade Chave",
            closingReason: "Motivo Encerramento",
            saleValue: "Valor da Venda",
            closingReasonLoss: "Motivo Negócio Perdido",
            closingOperatorName: "Operador Encerrado",
            firstOperatorName: "Operador Inicial",
            currentOperator: "Operador Atual",
            csat: "CSAT",
            nps: "NPS",
            tma: "TMA",
            tmr: "TMR",
            dateResponseSurvey: "Data de Resposta da Pesquisa",
            sourceReferral: "Source",
            idReferral: "ID",
            urlReferral: "URL",
            headlineReferral: "Headline",
            bodyReferral: "Body",
            firstMessage: "Primeira Mensagem",
          };

          let tagsLength = 0;
          if (conversations.length)
            conversations.forEach((conversation) => {
              const chat = chats.find((el) => el._id === conversation.chat);
              const contact = conversation.inboxContact
                ? contacts.find((el) => el._id === conversation.inboxContact)
                : {};
              const operator =
                conversation.closingOperator || conversation.operator
                  ? operators.find((el) =>
                      conversation.closingOperator
                        ? el._id === conversation.closingOperator
                        : el._id === conversation.operator
                    )
                  : {};
              const firstOperator = conversation.firstOperator
                ? operators.find((el) => el._id === conversation.firstOperator)
                : {};
              const operatorData =
                operator && operator.user
                  ? operatorsData.find((el) => el._id === operator.user)
                  : {};
              const firstOperatorData =
                firstOperator && firstOperator.user
                  ? operatorsData.find((el) => el._id === firstOperator.user)
                  : {};
              const currentOperator = conversation.operator
                ? operators.find((el) => el._id === conversation.operator)
                : {};
              const currentOperatorData =
                currentOperator && currentOperator.user
                  ? operatorsData.find((el) => el._id === currentOperator.user)
                  : {};
              const survey = surveys.find(
                (el) => el.conversationId === conversation._id
              );
              let csat = "";
              if (survey && survey.type === "CSAT") {
                if (survey.score === 0) csat = "Insatisfeito";
                if (survey.score === 1) csat = "Neutro";
                if (survey.score === 2) csat = "Satisfeito";
              }
              let tma = "",
                tmr = "";
              if (conversation && conversation.kpis) {
                if (conversation.kpis.tma) tma = conversation.kpis.tma;
                if (conversation.kpis.tmr) tmr = conversation.kpis.tmr;
              }

              const itemData = {
                "Data Entrada": dateFormatter(conversation.createdAt),
                "Data Encerrado": conversation.closingDate
                  ? dateFormatter(conversation.closingDate)
                  : "",
                "ID da conversa": conversation._id || "",
                Chatbot: (chat.name || "N/I")
                  .toUpperCase()
                  .replace(/\n+|\r+|,+/g, ""),
                Nome: (contact.name || "Sem Nome")
                  .toUpperCase()
                  .replace(/\n+|\r+|,+/g, ""),
                Telefone: (contact.phone || "").replace(/\n+|\r+|,+/g, ""),
                Lgpd:
                  contact && "optIn" in contact
                    ? contact.optIn === true
                      ? "Opt-in"
                      : "Opt-out"
                    : "Não informado",
                Email: (contact.email || "")
                  .toUpperCase()
                  .replace(/\n+|\r+|,+/g, ""),
                Tipo: (conversation.origin || "")
                  .toUpperCase()
                  .replace(/\n+|\r+|,+/g, ""),
                Canal: (conversation.channel || "")
                  .toUpperCase()
                  .replace(/\n+|\r+|,+/g, ""),
                "Status Atual":
                  conversation.status === 4 ? "ENCERRADO" : "ATIVO",
                "Intenção Atual":
                  conversation.intentions && conversation.intentions.length > 0
                    ? conversation.intentions
                        .join(" - ")
                        .toUpperCase()
                        .replace(/\n+|\r+|,+/g, "")
                    : "Não Informado".toUpperCase(),
                "Entidade Chave":
                  conversation.entities && conversation.entities.length > 0
                    ? conversation.entities
                        .join(" - ")
                        .toUpperCase()
                        .replace(/\n+|\r+|,+/g, "")
                    : "",
                "Motivo Encerramento": (conversation.closingReason || "")
                  .toUpperCase()
                  .replace(/\n+|\r+|,+/g, ""),
                "Valor da Venda": conversation.saleValue
                  ? String(conversation.saleValue).replace(/\n+|\r+|,+/g, "")
                  : "",
                "Motivo Negócio Perdido": conversation.closingReasonLoss
                  ? conversation.closingReasonLoss
                  : "",
                "Operador Inicial": (firstOperatorData.name || "")
                  .toUpperCase()
                  .replace(/\n+|\r+|,+/g, ""),
                "Operador Atual": (currentOperatorData.name || "")
                  .toUpperCase()
                  .replace(/\n+|\r+|,+/g, ""),
                "Operador Encerrado": (conversation.status === 4
                  ? operatorData.name || "SISTEMA"
                  : ""
                )
                  .toUpperCase()
                  .replace(/\n+|\r+|,+/g, ""),
                CSAT: csat ? csat.toUpperCase() : "",
                NPS: survey && survey.type === "NPS" ? survey.score : "",
                TMA: tma,
                TMR: tmr,
                "Data de Resposta da Pesquisa": survey
                  ? new Date(survey.createdAt)
                      .toLocaleString()
                      .replace(",", " - ")
                  : "",
                Source:
                  conversation.referral && conversation.referral.source_type
                    ? conversation.referral.source_type
                    : "",
                ID:
                  conversation.referral && conversation.referral.source_id
                    ? conversation.referral.source_id
                    : "",
                URL:
                  conversation.referral && conversation.referral.source_url
                    ? conversation.referral.source_url
                    : "",
                Headline:
                  conversation.referral && conversation.referral.headline
                    ? conversation.referral.headline
                    : "",
                Body:
                  conversation.referral && conversation.referral.body
                    ? conversation.referral.body
                    : "",
                "Primeira Mensagem": conversation.firstMessage
                  ? conversation.firstMessage.replace(/\n+|\r+|,+/g, "")
                  : "",
              };

              if (contact.tags.length > 0) {
                if (tagsLength < contact.tags.length)
                  tagsLength = contact.tags.length;
                for (let i = 0; i < tagsLength; i += 1) {
                  const j = i + 1;
                  itemData[`tag${j}`] = "";
                }
                for (let i = 0; i < contact.tags.length; i += 1) {
                  const j = i + 1;
                  if (!headers[`tag${j}`]) headers[`tag${j}`] = `Tag ${j}`;
                  itemData[`tag${j}`] =
                    `${contact.tags[i].label}: ${contact.tags[i].value}`
                      .toUpperCase()
                      .replace(/\n+|\r+|,+/g, "");
                }
              }
              arrayXlsx.push(itemData);
            });
          else {
            // cria um xls só com o header
            arrayXlsx.push({
              "Data Entrada": "",
              "Data Encerrado": "",
              "ID da conversa": "",
              Chatbot: "",
              Nome: "",
              Telefone: "",
              Email: "",
              Lgpd: "",
              Tipo: "",
              Canal: "",
              "Status Atual": "",
              "Intenção Atual": "",
              "Entidade Chave": "",
              "Motivo Encerramento": "",
              "Valor da Venda": "",
              "Motivo Negócio Perdido": "",
              "Operador Inicial": "",
              "Operador Atual": "",
              "Operador Encerrado": "",
              CSAT: "",
              NPS: "",
              TMA: "",
              TMR: "",
              "Data de Resposta da Pesquisa": "",
              Source: "",
              ID: "",
              URL: "",
              Headline: "",
              Body: "",
              "Primeira Mensagem": "",
            });
          }
          const worksheet = XLSX.utils.json_to_sheet(arrayXlsx);
          XLSX.utils.book_append_sheet(workbook, worksheet, "report");
          XLSX.writeFile(workbook, "inbox-conversations-report.xlsx");
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.vs-popup--content) {
  height: 332px;
  max-height: none;
}

#dateFilter {
  margin-left: 10px;
}
.mx-datepicker {
  width: -webkit-fill-available;
}

.mx-datepicker-main {
  z-index: 40000;
}

:deep(.mx-input) {
  width: 100%;
  height: 39px;
  padding: 10px;
  font-size: 1rem;
  color: inherit;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  transition: all 0.2s ease;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.mx-datepicker) {
  width: 100%;
  height: 39px;
}
</style>
